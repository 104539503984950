"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isAllowOrigin = exports.isValidUrl = void 0;
function isValidUrl(url) {
    if (!url && !(url === null || url === void 0 ? void 0 : url.trim()))
        return false;
    try {
        new URL(url);
    }
    catch (_) {
        return false;
    }
    return true;
}
exports.isValidUrl = isValidUrl;
function isAllowOrigin(url, debug) {
    if (debug) {
        return Boolean((url === null || url === void 0 ? void 0 : url.trim().includes('identity.trueid')) || (url === null || url === void 0 ? void 0 : url.trim().includes('localhost')));
    }
    else {
        return Boolean(url === null || url === void 0 ? void 0 : url.trim().includes('identity.trueid'));
    }
}
exports.isAllowOrigin = isAllowOrigin;

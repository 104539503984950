import TrueIDAuth from '@tdg/trueid-web-sdk';
import './App.css';
import { useState, useEffect } from 'react';

function App() {
  const [client, setClient] = useState(null);
  const [clientFullPage, setClientFullPage] = useState(null);
  const [sessionStatus, setSessionStatus] = useState({});

  const handleLogin = (state) => {
    setSessionStatus(state);
  };

  const isAuthenticated = !!sessionStatus.access_token;

  useEffect(() => {
    // popup
    const config = {
      client_id: 190,
      redirect_uri: window.location.origin,
      debug: true,
      env: process.env.REACT_APP_ENV,
    };
    const auth = new TrueIDAuth(config);
    setClient(auth);
    auth.getStatus().then((state) => {
      setSessionStatus(state);
    });

    // full page
    const configFullPage = {
      client_id: 190,
      redirect_uri: window.location.origin,
      debug: true,
      flow: 'redirects',
      env: process.env.REACT_APP_ENV,
    };
    const authFullPage = new TrueIDAuth(configFullPage);
    setClientFullPage(authFullPage);
    authFullPage.getStatus().then((state) => {
      setSessionStatus(state);
    });
  }, []);

  return (
    <div className="App">
      <h1>Normal CRA Site</h1>
      <h4>{JSON.stringify(client?.config, null, 2)}</h4>

      <div>
        <button
          id="home_button_get_status"
          onClick={async () => {
            const session = await client.getStatus();
            setSessionStatus(session);
          }}
        >
          Get Status
        </button>
        {!isAuthenticated && (
          <button id="home_button_login" onClick={() => client.login(handleLogin)}>
            Login
          </button>
        )}
        {!isAuthenticated && (
          <button id="home_button_login_full_page" onClick={() => clientFullPage.login(handleLogin)}>
            Login Full Page
          </button>
        )}
        {isAuthenticated && (
          <button id="home_button_logout" onClick={() => client.logout()}>
            Logout
          </button>
        )}
      </div>
      <div className="table-container">
        <table>
          <tbody>
            <tr>
              <td>status:</td>
              <td>
                <code id="home_text_status">{sessionStatus.status || 'null'}</code>
              </td>
            </tr>
            <tr>
              <td>token:</td>
              <td>
                <code id="home_text_access_token">{sessionStatus.access_token || 'null'}</code>
              </td>
            </tr>
            <tr>
              <td>income:</td>
              <td>
                <code id="home_text_income">{sessionStatus.income || 'null'}</code>
              </td>
            </tr>
            <tr>
              <td>raw:</td>
              <td>
                <code id="home_text_raw">{JSON.stringify(sessionStatus)}</code>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <a href="/cdn-import.html" target="_blank">
        Demo Site with CDN import
      </a>
    </div>
  );
}

export default App;

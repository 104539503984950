"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var helpers_1 = require("./helpers");
function resolveAuthServer(env) {
    switch (env) {
        case 'prod': {
            return {
                baseUrl: 'https://identity.trueid.net',
            };
        }
        case 'nonprod':
        default: {
            return {
                // baseUrl: process.env.REACT_APP_DEBUG_AUTH_SERVER || 'https://identity.trueid-dev.net',
                // baseUrl: 'http://localhost:3000',
                baseUrl: 'https://identity.trueid-dev.net',
            };
        }
    }
}
/**
 * Authentication client to use connecting to TrueID Authentication System
 *
 * Reference:
 *  * https://oauth.net/2/grant-types/
 *  * https://www.oauth.com/playground/index.html
 *  * https://auth0.com/docs/libraries
 *
 */
var TrueIDAuth = /** @class */ (function () {
    function TrueIDAuth(config) {
        if (!config.env) {
            config.env = 'nonprod';
        }
        config.debug = Boolean(config.debug);
        if (!config.client_id)
            throw new Error('missing client_id');
        if (!(0, helpers_1.isValidUrl)(config.redirect_uri)) {
            throw new Error('invalid redirect_uri');
        }
        this._config = config;
        this._authServer = resolveAuthServer(config.env);
    }
    Object.defineProperty(TrueIDAuth.prototype, "config", {
        /** configuration getter method */
        get: function () {
            return this._config;
        },
        enumerable: false,
        configurable: true
    });
    /**
     * Invoke this function to open an login page. This behavior and parameters will be according to your configuration.
     * If the user is already login, this function will invoke callback with user authentication status.
     */
    TrueIDAuth.prototype.login = function (callback) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var targetUrl, i, o, a, s;
            var _this = this;
            return __generator(this, function (_c) {
                targetUrl = new URL(this._authServer.baseUrl);
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if (window.Cypress) {
                    this._config.flow = 'redirects';
                    this._config.redirect_uri = window.location.href;
                }
                if (this._config.client_id) {
                    targetUrl.searchParams.append('client_id', this._config.client_id.toString());
                }
                if (this._config.redirect_uri) {
                    targetUrl.searchParams.append('redirect_uri', this._config.redirect_uri.toString());
                }
                targetUrl.searchParams.append('flow', this._config.flow || 'popup');
                targetUrl.searchParams.append('lang', (_a = this._config.lang) !== null && _a !== void 0 ? _a : 'en');
                if (this._config.scope)
                    targetUrl.searchParams.append('scope', this._config.scope);
                targetUrl.searchParams.append('state', this._config.state || Math.floor(Math.random() * 1000).toString());
                switch (this._config.flow) {
                    case 'redirects': {
                        window.location.assign(targetUrl.toString());
                        break;
                    }
                    case 'popup':
                    default: {
                        i = 600, o = 600, a = document.body.clientWidth / 2 - i / 2, s = screen.height / 2 - o / 2;
                        this._loginWindow = window.open(targetUrl.toString(), 'trueid_login_popup', 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' +
                            i +
                            ', height=' +
                            o +
                            ', top=' +
                            s +
                            ', left=' +
                            a);
                        console.log('== this._loginWindow', this._loginWindow);
                        (_b = this._loginWindow) === null || _b === void 0 ? void 0 : _b.focus();
                        window.addEventListener('message', function (ev) {
                            var _a, _b;
                            if (ev.isTrusted && (0, helpers_1.isAllowOrigin)(ev.origin, _this._config.debug)) {
                                if (_this._config.debug)
                                    console.log('[events]', ev);
                                switch ((_a = ev.data) === null || _a === void 0 ? void 0 : _a.action) {
                                    case 'login_success': {
                                        (_b = _this._loginWindow) === null || _b === void 0 ? void 0 : _b.close();
                                        _this._loginWindow = null;
                                        window.focus();
                                        if (callback)
                                            callback(ev.data);
                                    }
                                    default:
                                        break;
                                }
                            }
                        });
                        if (this._config.debug)
                            window.postMessage('initial', '*');
                        break;
                    }
                }
                if (this._config.debug) {
                    console.log(this._loginWindow);
                    console.log('loginUrl', targetUrl.toString());
                }
                return [2 /*return*/];
            });
        });
    };
    /**
     * Invoke this function to redirect to signout page and terminate your user sessions.
     */
    TrueIDAuth.prototype.logout = function () {
        return __awaiter(this, void 0, void 0, function () {
            var data, logoutUrl;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getStatus()];
                    case 1:
                        data = _a.sent();
                        if (data.status === 'connected') {
                            logoutUrl = new URL(this._authServer.baseUrl);
                            logoutUrl.pathname = 'signout';
                            if (this._config.client_id) {
                                logoutUrl.searchParams.append('client_id', this._config.client_id.toString());
                            }
                            if (this._config.redirect_uri) {
                                logoutUrl.searchParams.append('redirect_uri', this._config.redirect_uri.toString());
                            }
                            window.location.assign(logoutUrl.toString());
                            if (this._config.debug) {
                                console.log('logoutUrl', logoutUrl.toString());
                            }
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Get current user authentication status of your user.
     */
    TrueIDAuth.prototype.getStatus = function () {
        return __awaiter(this, void 0, void 0, function () {
            var checkSessionUrl, resp, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        checkSessionUrl = new URL("".concat(this._authServer.baseUrl, "/api/session/check-sso?client_id=").concat(this._config.client_id));
                        return [4 /*yield*/, fetch(checkSessionUrl.toString(), { credentials: 'include' })];
                    case 1:
                        resp = _a.sent();
                        return [4 /*yield*/, resp.json()];
                    case 2:
                        data = _a.sent();
                        return [2 /*return*/, __assign({}, data)];
                }
            });
        });
    };
    return TrueIDAuth;
}());
exports.default = TrueIDAuth;

import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import KeycloakPage from './keycloak';

ReactDOM.render(
  <React.StrictMode>
    <>
      <App />
      {/* <KeycloakPage /> */}
      <div id="commit_id">commit: {process.env.REACT_APP_GIT_SHA}</div>
    </>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
